import { createRouter, createWebHistory } from "vue-router";
import Login from "../components/Login";
import TermsPop from "../components/TermsPop.vue";
import SetPassword from "../components/SetPassword.vue";
import OtpCode from "../components/OtpCode.vue";
import OtpCodeNew from "../components/OtpCodeNew.vue";
import ResetPassword from "../components/ResetPassword.vue";
import Signup from "../components/Signup.vue";
import BillingPlanGlobal from "../components/BillingPlanGlobal.vue";
import Faq_page from "../components/Faq_page.vue";
import DashboardPartner from "../components/DashboardPartner.vue";
import DashboardDescriptiveAnalystics from "../components/Dashboard/Descriptive/DashboardDescriptiveAnalystics.vue";
import DescriptiveAnalysticsdetails from "../components/Dashboard/Descriptive/DescriptiveAnalysticsdetails.vue";
import DescriptiveTestWise from "../components/Dashboard/Descriptive/TestWise.vue";
import DescriptiveOverview from "../components/Dashboard/Descriptive/Overview.vue";
import DashboardOverview from  "../components/DashboardGroup.vue";
import InviteLogs from "../components/Test-Settings/Invite/Logs.vue";

import DescriptiveAnalysticsCountry from "../components/Dashboard/Descriptive/DescriptiveAnalysticsCountry.vue";
import ActiveTestEditInstance from "../components/Test-Settings/ActiveTestEditInstance.vue";
import DashboardGroup from "../components/Dashboard/Overview/OverviewDashboard.vue";
import ActiveTests from "../components/ActiveTests.vue";
import ArchiveTests from "../components/ArchiveTests.vue";
import CandidateDetails from "../components/CandidateDetails.vue";
import ActiveTestInviteCandidates from "../components/ActiveTestInviteCandidates.vue";
import ActiveTestViewCandidates from "../components/ActiveTestViewCandidates.vue";
import ActiveTestViewCandidatesNew from "../components/ActiveTestViewCandidatesNew.vue";
import ArchiveTestViewCandidates from "../components/ArchiveTestViewCandidates.vue";
import DashboardTeam from "../components/DashboardTeam.vue";
import InviteCandidatesOld from "../components/InviteCandidatesOld.vue";
import ManagementPartners from "../components/ManagementPartners.vue";
import ManagementRoles from "../components/ManagementRoles.vue";
import ManagementTeams from "../components/ManagementTeams.vue";
import ManagementUsers from "../components/ManagementUsers.vue";
import ManagementUsersPanding from "../components/ManagementUsersPanding.vue";
import ManagementUsersDeactivated from "../components/ManagementUsersDeactivated.vue";
import ManagementCreateRoles from "../components/ManagementCreateRoles.vue";
import ManagementEditRoles from "../components/ManagementEditRoles.vue";
import ManagementPartnersAssignTeam from "../components/ManagementPartnersAssignTeam.vue";
// import Index from "../components/Index.vue";
import ManagementTeamMembers from "../components/ManagementTeamMembers.vue";
import Profile from "../components/Profile.vue";
import ResetPasswordLink from "../components/ResetPasswordLink.vue";
import TestDetail from "../components/TestDetail.vue";
import TestManagement from "../components/TestManagement.vue";
import TestManagementDetail from "../components/TestManagementDetail.vue";
import TokenmanagementViewRegion from "../components/TokenmanagementViewRegion.vue";
import TokenmanagementViewTest from "../components/TokenmanagementViewTest.vue";
import TestManagementPartnerDetail from "../components/TestManagementPartnerDetail.vue";
import TestManagementPartnerTeamDetail from "../components/TestManagementPartnerTeamDetail.vue";
import TestLibrary from "../components/TestLibrary.vue";
import TestLibraryBookmark from "../components/TestLibraryBookmark.vue";
import InviteCandidatePage from "../components/InviteCandidatePage.vue"
import ViewCandidates from "../components/ViewCandidates.vue";
import ViewPartnerTeamMembers from "../components/ViewPartnerTeamMembers.vue";
import TermsofService from "../components/TermsofService.vue";
import PrivacyPolicy from "../components/PrivacyPolicy.vue";
import PageNotFound from "../components/PageNotFound.vue";
import Internet from "../components/Internet.vue";
import VerifyUser from "../components/VerifyUser.vue";
import Invitation from "../components/Invitation.vue";
import LinkExpired from "../components/LinkExpired.vue";
import ErrorPage from "../components/ErrorPage.vue";
import VerifySuccessful from "../components/VerifySuccessful.vue";
import AlreadyVerify from "../components/AlreadyVerify.vue";
import ManagementPartnersDetail from "../components/managementPartnerDetail.vue";
import ManagementPartnersUserDetail from  "../components/ManagementPartnersUserDetail.vue";
import ManagementPartnersGroupDetail from  "../components/ManagementPartnersGroupDetail.vue";
import store from "../store";
import AssignedTeamDetails from "../components/AssignedTeamDetails.vue";
import ManagementTeamsGroupsActivated from "../components/ManagementTeamsGroupsActivated.vue";
import ManagementTeamsGroupsDeactivated from "../components/ManagementTeamsGroupsDeactivated.vue";
import AddMemberlist from "../components/AddMemberlist.vue";
import inviteCandidate from "../../src/components/inviteCandidate.vue";
import UserAccountPopup from "../../src/components/UserAccountPopup.vue";
import Dashboard from "../components/Dashboard.vue";
import Logout from "../components/Logout.vue";
import ActiveTestReportOverview from "../components/ActiveTestReportOverview.vue";
import ActiveTestReportCognitive from "../components/ActiveTestReportCognitive.vue";
import ActiveTestReportBehavioural from "../components/ActiveTestReportBehavioural.vue";
import ActiveTestReportLanguage from "../components/ActiveTestReportLanguage.vue";
import ActiveTestReportProctor from "../components/ActiveTestReportProctor.vue";
import ProctorPDF from "../components/ProctorPDF.vue"
import ResponsesPDF from "../components/ResponsesPDF.vue"
import BehaviouralPDF from "../components/BehaviouralPDF.vue"
import ActiveTestReportResponses from "../components/ActiveTestReportResponses.vue";
import ActiveTestReportOverviewPDF from "../components/ActiveTestReportOverviewPDF.vue";
import ActiveTestReportCognitivePDF from "../components/ActiveTestReportCognitivePDF.vue";
import ActiveTestReportLanguagePDF from "../components/ActiveTestReportLanguagePDF.vue";

import BillingInvoice from "../components/BillingInvoice.vue";
import BillingPlan from "../components/BillingPlan.vue";
import BillingOverview from "../components/BillingOverview.vue";
import InviteCandidatesNew from "../components/InviteCandidatesNew.vue";
import VerifyPaymentStatusOld from "../components/VerifyPaymentStatusOld.vue";
import VerifyPaymentStatus from "../components/VerifyPaymentStatus.vue";
import BillingDetails from "../components/Billing/Details.vue";
import IntegrationSetting from "../components/settings/IntegrationSetting.vue";
import InvoicePreferenceSetting from "../components/settings/InvoicePreferenceSetting.vue";
import AccountSettings from "../components/settings/AccountSettings.vue";
import BillingPlans from "../components/settings/BillingPlans.vue";
import TestLibraryTour from "../components/TourComponents/TestLibraryTour.vue";
import DashboardTour from "../components/TourComponents/DashboardTour.vue";
import ActiveTestTour from "../components/TourComponents/ActiveTestTour.vue";
import ActiveTestViewCandidatesTour from '../components/TourComponents/ActiveTestViewCandidatesTour.vue';
import OverviewTokenManagementTour from '../components/TourComponents/OverviewTokenManagementTour.vue';
import TestTokenManagementTour from '../components/TourComponents/TestTokenManagementTour.vue';
import revampColour from "../components/revampColour.vue";
import Billing from "../components/Billing.vue";
import TokenLogs from "../components/TokenManagement/TokenLogs.vue";
import eventBus from "../store/eventBus";
import TestWisePDF from "../components/Dashboard/Descriptive/TestWisePDF.vue";

const routes = [
  {
    path: "/",
    redirect: "/login",
    component: () => import("@/layout/Layout.vue"),
    beforeEnter: (to, from, next) => {
      eventBus.emit('show-button');
      if (!navigator.onLine) {
        next({ name: "Internet" });
      } else if (store.state.auth.authenticate) next();
      else next({ name: "Login",query:{redirect:to.fullPath} });
    },
    children: [
      {
        path: "/Active-Test-Report-Overview/:candidateId/:testId",
        name: "ActiveTestReportOverview",
        component: ActiveTestReportOverview,
      },
      {
        path: "/Active-Test-Report-Cognitive/:candidateId/:testId",
        name: "ActiveTestReportCognitive",
        component: ActiveTestReportCognitive,
      },
      {
        path: "/Active-Test-Report-Language/:candidateId/:testId",
        name: "ActiveTestReportLanguage",
        component: ActiveTestReportLanguage,
      },
      {
        path: "/Active-Test-Report-Proctor/:candidateId/:testId",
        name: "ActiveTestReportProctor",
        component: ActiveTestReportProctor,
      },
      {
        path: "/Activ-Test-Report-Responses/:candidateId/:testId",
        name: "ActiveTestReportResponses",
        component: ActiveTestReportResponses,
      },
      {
        path: "/Active-Test-Report-Behavioural/:candidateId/:testId",
        name: "ActiveTestReportBehavioural",
        component: ActiveTestReportBehavioural,
      },
      {
        path: "/Proctor-PDF",
        name: "ProctorPDF",
        component: ProctorPDF,
      },
      {
        path: "/Responses-PDF",
        name: "ResponsesPDF",
        component: ResponsesPDF,
      },
      // {
      //   path: "/Behavioural-PDF",
      //   name: "BehaviouralPDF",
      //   component: BehaviouralPDF,
      // },
      // {
      //   path: "/Active-Test-Report-OverviewPDF",
      //   name: "ActiveTestReportOverviewPDF",
      //   component: ActiveTestReportOverviewPDF,
      // },
      // {
      //   path: "/Active-Test-Report-CognitivePDF",
      //   name: "ActiveTestReportCognitivePDF",
      //   component: ActiveTestReportCognitivePDF,
      // },
      {
        path: "/dashboard-partner",
        name: "DashboardPartner",
        component: DashboardPartner,
      },
      {
        path: "/dashboard-group",
        name: "DashboardGroup",
        component: DashboardGroup,
      },
      {
        path: "/dashboard/descriptive-analytics",
        name: "DashboardDescriptiveAnalystics",
        component: DashboardDescriptiveAnalystics,
      },
      {
        path: "/edit-instance",
        name: "ActiveTestEditInstance",
        component: ActiveTestEditInstance,
      },
      {
        path: "/invite-logs",
        name: "InviteLogs",
        component: InviteLogs,
      },
      {
        path: "/dashboard/descriptive/descriptive-analystics-details",
        name: "DescriptiveAnalysticsdetails",
        component: DescriptiveAnalysticsdetails,
      },
      {
        path: "/dashboard/descriptive/test-wise",
        name: "DescriptiveTestWise",
        component: DescriptiveTestWise,
      },
      {
        path: "/dashboard/descriptive/overview",
        name: "DescriptiveOverview",
        component: DescriptiveOverview,
      },
      {
        path: "/dashboard/overview",
        name: "DashboardOverview",
        component: DashboardOverview,
      },
      {
        path: "/dashboard/descriptive/descriptive-analystics-country",
        name: "DescriptiveAnalysticsCountry",
        component: DescriptiveAnalysticsCountry,
      },
      {
        path: `/active-tests`,
        name: "ActiveTests",
        component: ActiveTests,
      },
      {
        path: `/test-archive`,
        name: "ArchiveTests",
        component: ArchiveTests,
      },
      {
        path:`/candidate-details`,
        name:"CandidateDetails",
        component: CandidateDetails,
      },
      {
        path:`/billing`,
        name:"Billing",
        component: Billing,
      },
      
      {
        path:`/billing-invoice`,
        name:"BillingInvoice",
        component: BillingInvoice,
      },
      
      {
        path:`/billing-overview`,
        name:"BillingOverview",
        component: BillingOverview,
      },
        
      // {
      //   path:`/verify-payment-status`,
      //   name:"VerifyPaymentStatusOld",
      //   component: VerifyPaymentStatusOld,
      // },
    
      {
        path:`/billing-plan`,
        name:"BillingPlan",
        component: BillingPlan,   
      },
      {
        path: "/active-test-invite-candidates/ids/:id/clients/:clientId",
        name: "ActiveTestInviteCandidates",
        component: ActiveTestInviteCandidates,
      },
      {
        path: "/active-test-view-candidates/tests/:testId/clients/:clientId/parents/:isParent",
        name: "ActiveTestViewCandidates",
        component: ActiveTestViewCandidates,
      },
      {
        path: "/active-test-view-candidates-new/tests/:testId/clients/:clientId/parents/:isParent",
        name: "ActiveTestViewCandidatesNew",
        component: ActiveTestViewCandidatesNew,
      },
      {
        path: "/archive-test-view-candidates/testId=:testId/clientId=:clientId/isParent=:isParent",
        name: "ArchiveTestViewCandidates",
        component: ArchiveTestViewCandidates,
      },
      {
        path: "/dashboard-team",
        name: "DashboardTeam",
        component: DashboardTeam,
      },
      {
        path: "/InviteCandidatesOld",
        name: "InviteCandidatesOld",
        component: InviteCandidatesOld,
      },
      {
        path: "/invitecandidate",
        name: "inviteCandidate",
        component: inviteCandidate,
      },
      {
        path: "/inviteCandidateNew",
        name: "inviteCandidateNew",
        component: InviteCandidatesNew,
      },
      {
        path: "/management-partners",
        name: "ManagementPartners",
        component: ManagementPartners,
      },
      {
        path: "/management-roles",
        name: "ManagementRoles",
        component: ManagementRoles,
      },
      {
        path: "/management-teams",
        name: "ManagementTeams",
        component: ManagementTeams,
      },
      {
        path: "/managementTeams-groups-activated",
        name: "ManagementTeamsGroupsActivated",
        component: ManagementTeamsGroupsActivated,
      },
      {
        path: "/managementTeams-groups-deactivated",
        name: "ManagementTeamsGroupsDeactivated",
        component: ManagementTeamsGroupsDeactivated,
      },
      {
        path: "/add-member-list/id/:id/status/:status",
        name: "AddMemberlist",
        component: AddMemberlist,
      },
      {
        path: "/management-users",
        name: "ManagementUsers",
        component: ManagementUsers,
      },
      {
        path: "/management-users-panding",
        name: "ManagementUsersPanding",
        component: ManagementUsersPanding,
      },
      {
        path: "/management-users-deactivated",
        name: "ManagementUsersDeactivated",
        component: ManagementUsersDeactivated,
      },
      {
        path: "/management-create-roles",
        name: "ManagementCreateRoles",
        component: ManagementCreateRoles,
      },
      {
        path: "/management-edit-roles/id/:id",
        name: "ManagementEditRoles",
        component: ManagementEditRoles,
      },
      {
        path: "/management-partners-assign-team",
        name: "ManagementPartnersAssignTeam",
        component: ManagementPartnersAssignTeam,
      },
      {
        path: "/management-patner-detail/id/:id/offsets/:offset/currents/:current",
        name: "ManagementPartnersDetail",
        component: ManagementPartnersDetail,
      },
      {
        path: "/management-partner-user-detail/id=:id/offset=:offset/current=:current",
        name: "ManagementPartnersUserDetail",
        component: ManagementPartnersUserDetail,
      },
      {
        path: "/management-partner-group-detail/id=:id/offset=:offset/current=:current",
        name: "ManagementPartnersGroupDetail",
        component: ManagementPartnersGroupDetail,
      },
      // {
      //   path: "/index",
      //   name: "Index",
      //   component: Index,
      // },
      {
        path: "/management-team-members",
        name: "ManagementTeamMembers",
        component: ManagementTeamMembers,
      },
      {
        path: "/profile",
        name: "Profile",
        component: Profile,
      },
      {
        path: "/test-detail",
        name: "TestDetail",
        component: TestDetail,
      },
      {
        path: "/test-management",
        name: "TestManagement",
        component: TestManagement,
      },
      {
        path: "/test-management/id/:id",
        name: "TestManagementDetail",
        component: TestManagementDetail,
      },
      {
        path: "/Token-Management/logs",
        name: "TokenLogs",
        component: TokenLogs,
      },
      {
        path: "/token-management-view-test",
        name: "TokenmanagementViewTest",
        component: TokenmanagementViewTest,
      },
      {
        path: "/token-management-view-region",
        name: "TokenmanagementViewRegion",
        component: TokenmanagementViewRegion,
      },
      {
        path: "/test-management-partner-detail",
        name: "TestManagementPartnerDetail",
        component: TestManagementPartnerDetail,
      },
      {
        path: "/test-management-partner-team-detail",
        name: "TestManagementPartnerTeamDetail",
        component: TestManagementPartnerTeamDetail,
      },
      {
        path: "/test-library",
        name: "TestLibrary",
        component: TestLibrary,
      },
      {
        path: "/test-library-bookmark",
        name: "TestLibraryBookmark",
        component: TestLibraryBookmark,
      },
      {
        path: "/invite-candidate-page/id=:id/clientId=:clientId/testName=:testName",
        name: "InviteCandidatePage",
        component: InviteCandidatePage,
      },
      {
        path: "/Assigned-Team-Details/groups/:grpid/offsets/:offset",
        name: "AssignedTeamDetails",
        component: AssignedTeamDetails,
      },
      {
        path: "/view-candidates",
        name: "ViewCandidates",
        component: ViewCandidates,
      },
      {
        path: "/view-partner-team-members/id/:id",
        name: "ViewPartnerTeamMembers",
        component: ViewPartnerTeamMembers,
      },
      {
        path: "/settings/integration",
        name: "Integration",
        component: IntegrationSetting,
      },    

      {
        path: "/settings/invoice-preference",
        name: "InvoicePreferenceSetting",
        component: InvoicePreferenceSetting,
      },  
      {
        path: "/settings/account-settings",
        name: "AccountSettings",
        component: AccountSettings,
      },
      {
        path: "/settings/plans",
        name: "BillingPlans",
        component: BillingPlans,
      },
      {
        path: "/test-library-tour",
        name: "TestLibraryTour",
        component: TestLibraryTour
      },
      {
        path: "/dashboard-tour",
        name: "DashboardTour",
        component: DashboardTour
      },
      {
        path: "/active-tests-tour",
        name: "ActiveTestTour",
        component: ActiveTestTour
      },
      {
        path:`/billing-details`,
        name:"Details",
        component: BillingDetails,
      },
      {
        path:`/color`,
        name:"revampColour",
        component: revampColour,
      },
      
      {
        path: "/view-candidates-tour",
        name: "ActiveTestViewCandidatesTour",
        component: ActiveTestViewCandidatesTour
      },
      {
        path: "/overview-token-mgmt-tour",
        name: 'OverviewTokenManagementTour',
        component: OverviewTokenManagementTour
      },
      {
        path: "/test-token-mgmt-tour",
        name: 'TestTokenManagementTour',
        component: TestTokenManagementTour
      }
    ],
  },
  {
    path: "/",
    component: () =>
      import("@/layout/Blank.vue"),
    children: [{
      path: "/dashboard",
      name: "Dashboard",
      beforeEnter(to, from, next) {
        window.location.href = window.app_base_url;
        next();
      },
      component: Dashboard

    },
    {
      path:`/verify-payment-status-old`,
      name:"VerifyPaymentStatusOld",
      component: VerifyPaymentStatusOld,
    },
    {
      path:`/verify-payment-status`,
      name:"VerifyPaymentStatus",
      component: VerifyPaymentStatus,
    },
    {
      path: "/dashboard/descriptive/test-wise/pdf",
      name: "TestWisePDF",
      component: TestWisePDF,
    },
    
    ],
  },
  {
    path: "/",
    component: () =>
      import("@/layout/Blank.vue"),
    children: [{
      path: "/logout",
      name: "Logout",
      beforeEnter: (to, from, next) => {
        if (store.state.auth.authenticate) next();
        else next({ name: "Login" });
      },
      component: Logout

    }
    ],
  },

  {
    path: "/",
    component: () => import("@/layout/Auth.vue"),
    beforeEnter: (to, from, next) => {
      if (!navigator.onLine) {
        next({ name: "Internet" });
      } else if (!store.state.auth.authenticate) next();
      else next({ name: "DashboardGroup" });
    },
    children: [
      {
        path: "/login",
        name: "Login",
        component: Login,
        query:{redirect:""}
      },
      // {
      //   path: "/set-password",
      //   name: "SetPassword",
      //   component: SetPassword,
      // },
      {
        path: "/otp-code",
        name: "OtpCode",
        component: OtpCode,
      },
      {
        path: "/otp-code-new/:token/:userId/:emailAddress",
        name: "OtpCodeNew",
        component: OtpCodeNew,
      },
      {
        path: "/reset-password",
        name: "ResetPassword",
        component: ResetPassword,
      },
      {
        path: "/signup",
        name: "Signup",
        component: Signup,
      },
      {
        path: "/billing",
        name: "BillingPlanGlobal",
        component: BillingPlanGlobal,
      },
      {
        path: "/Link_expired",
        name: "LinkExpired",
        component: LinkExpired,
      },
      // {
      //   path: "/Link_expired",
      //   name: "LinkExpired",
      //   component: LinkExpired,
      // },
      {
        path: "/verify-successful",
        name: "VerifySuccessful",
        component: VerifySuccessful,
      },
      // {
      //   path: "/already-verify",
      //   name: "AlreadyVerify",
      //   component: AlreadyVerify,
      // },
      // {
      //   path: "/Error_page",
      //   name: "ErrorPage",
      //   component: ErrorPage,
      // },
      {
        path: "/reset-password-link",
        name: "ResetPasswordLink",
        component: ResetPasswordLink,
      },
      // {
      //   path: "/users/verify",
      //   name: "VerifyUser",
      //   component: VerifyUser,
      // },
      {
        path: "/useraccountpopup",
        name: "UserAccountPopup",
        component: UserAccountPopup,
      },
    ],
  },
  {
    path: "/",
    component: () => import("@/layout/faq_page/Layout.vue"),
    beforeEnter: (to, from, next) => {
      if (!navigator.onLine) {
        next({ name: "Internet" });
      }
    },
    // children: [
    //   {
    //     path: "/faq_page",
    //     name: "Faq_page",
    //     component: Faq_page,
    //   },
    // ],
  },
  {
    path: "/faq_page",
    name: "Faq_page",
    component: Faq_page,
  },

  {
    path: "/terms-pop",
    name: "TermsPop",
    component: TermsPop,
  },
  {
    path: "/terms-of-service",
    name: "TermsofService",
    component: TermsofService,
  },
  {
    path: "/privacy-policy",
    name: "PrivacyPolicy",
    component: PrivacyPolicy,
  },
  {
    path: "/:pathmatch(.*)*",
    name: "PageNotFound",
    component: PageNotFound,
  },
  {
    path: "/internet",
    name: "Internet",
    component: Internet,
  },
  {
    path: "/invitation",
    name: "Invitation",
    component: Invitation,
  },
  {
    path: "/set-password",
    name: "SetPassword",
    component: SetPassword,
  },
  {
    path: "/users/verify",
    name: "VerifyUser",
    component: VerifyUser,
  },
  {
    path: "/already-verify",
    name: "AlreadyVerify",
    component: AlreadyVerify,
  },
  {
    path: "/Error_page",
    name: "ErrorPage",
    component: ErrorPage,
  },
  /**
   * pdf-routes
   */
  {
    path: "/Active-Test-Report-OverviewPDF",
    name: "ActiveTestReportOverviewPDF",
    component: ActiveTestReportOverviewPDF,
  },
  {
    path: "/Active-Test-Report-LanguagePDF",
    name: "ActiveTestReportLanguagePDF",
    component: ActiveTestReportLanguagePDF,
  },    {
    path: "/Active-Test-Report-CognitivePDF",
    name: "ActiveTestReportCognitivePDF",
    component: ActiveTestReportCognitivePDF,
  },
  {
    path: "/Behavioural-PDF",
    name: "BehaviouralPDF",
    component: BehaviouralPDF,
  },

];

const router = createRouter({
  history: createWebHistory(),
  routes,
  linkActiveClass: "active", 
});

router.beforeEach((to, from, next) => {
  if (to.matched.some(record => record.path === '/settings/plans')) {
    eventBus.emit('hide-button');
  } else {
    eventBus.emit('show-button');
  }
  next();
});

export default router;
